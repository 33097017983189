import { FunctionComponent, useState } from "react";
import styled from 'styled-components';

import logoSparrowhub from '../assets/images/logos/logo-sparrowhub.png';

type FooterProps = {

}

export const Footer: FunctionComponent<FooterProps> = ({ }) => {
  return (
    <StyledFooter className="Footer">
      <img className="Footer_logo" src={logoSparrowhub} alt="Sparrowhub" />
    </StyledFooter>
  );
}

const StyledFooter = styled.footer`
  width: 100%;
  height: 135px;
  padding: 0 88px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .Footer_logo {
    width: 176px;
    height: auto;
  }

  /* mobile CSS */
  @media screen and (max-width: 1080px) {
    height: 90px;
    padding: 0 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    .Footer_logo {
      width: 90px;
    }
  }
`;
