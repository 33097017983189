import { FunctionComponent, useState, useEffect } from "react";
import styled from 'styled-components';

import iconOpen from '../assets/images/icons/icon-open.svg';
import iconShopping from '../assets/images/icons/icon-shopping.svg';
import iconDoordash from '../assets/images/icons/icon-doordash.svg';
import iconAuspost from '../assets/images/icons/icon-auspost.png';

enum TileType {
  Primary = 'primary',
  Secondary = 'secondary'
}

enum TileState {
  Overdue = 'overdue',
  Positive = 'positive'
}

enum TileValueType {
  Number = 'number',
  Time = 'time'
}

interface DashboardTile {
  id: string
  label: string
  value: number
  valueType: TileValueType
  icon?: string
  state?: TileState
}

const initialDummyData = {
  open: 5,
  awaitingDoorDash: 1,
  awaitingAusPost: 0,
  awaitingClickCollect: 3,
  completed: 7,
  averageScriptTime: 7 * 60,
  averageOrderTime: 15 * 60,
  new: 15,
}

const dashboardValuesLoop = [
  {
    open: 5,
    awaitingDoorDash: 1,
    awaitingAusPost: 0,
    awaitingClickCollect: 3,
    completed: 7,
    averageScriptTime: 7.2 * 60,
    averageOrderTime: 14.9 * 60,
    new: 15,
  },
  {
    open: 5,
    awaitingDoorDash: 1,
    awaitingAusPost: 0,
    awaitingClickCollect: 3,
    completed: 7,
    averageScriptTime: 7.2 * 60,
    averageOrderTime: 14.9 * 60,
    new: 15,
  },
  {
    open: 4,
    awaitingDoorDash: 2,
    awaitingAusPost: 0,
    awaitingClickCollect: 3,
    completed: 7,
    averageScriptTime: 7.3 * 60,
    averageOrderTime: 14.9 * 60,
    new: 15,
  },
  {
    open: 4,
    awaitingDoorDash: 2,
    awaitingAusPost: 0,
    awaitingClickCollect: 3,
    completed: 7,
    averageScriptTime: 7.3 * 60,
    averageOrderTime: 14.4 * 60,
    new: 15,
  },
  {
    open: 4,
    awaitingDoorDash: 2,
    awaitingAusPost: 0,
    awaitingClickCollect: 3,
    completed: 7,
    averageScriptTime: 7.3 * 60,
    averageOrderTime: 14.4 * 60,
    new: 15,
  },
  {
    open: 2,
    awaitingDoorDash: 2,
    awaitingAusPost: 1,
    awaitingClickCollect: 4,
    completed: 7,
    averageScriptTime: 7.2 * 60,
    averageOrderTime: 14.4 * 60,
    new: 15,
  },
  {
    open: 2,
    awaitingDoorDash: 2,
    awaitingAusPost: 1,
    awaitingClickCollect: 4,
    completed: 7,
    averageScriptTime: 7.5 * 60,
    averageOrderTime: 14.6 * 60,
    new: 15,
  },
  {
    open: 2,
    awaitingDoorDash: 2,
    awaitingAusPost: 1,
    awaitingClickCollect: 4,
    completed: 7,
    averageScriptTime: 7.5 * 60,
    averageOrderTime: 14.6 * 60,
    new: 15,
  },
  {
    open: 2,
    awaitingDoorDash: 2,
    awaitingAusPost: 1,
    awaitingClickCollect: 2,
    completed: 9,
    averageScriptTime: 7.5 * 60,
    averageOrderTime: 14.6 * 60,
    new: 15,
  },
  {
    open: 1,
    awaitingDoorDash: 2,
    awaitingAusPost: 2,
    awaitingClickCollect: 2,
    completed: 9,
    averageScriptTime: 6.9 * 60,
    averageOrderTime: 14.6 * 60,
    new: 15,
  },
  {
    open: 1,
    awaitingDoorDash: 2,
    awaitingAusPost: 2,
    awaitingClickCollect: 2,
    completed: 9,
    averageScriptTime: 6.9 * 60,
    averageOrderTime: 14.6 * 60,
    new: 15,
  },
  {
    open: 0,
    awaitingDoorDash: 2,
    awaitingAusPost: 3,
    awaitingClickCollect: 2,
    completed: 9,
    averageScriptTime: 6.8 * 60,
    averageOrderTime: 14.6 * 60,
    new: 15,
  },
  {
    open: 0,
    awaitingDoorDash: 2,
    awaitingAusPost: 4,
    awaitingClickCollect: 2,
    completed: 9,
    averageScriptTime: 6.8 * 60,
    averageOrderTime: 14.1 * 60,
    new: 15,
  },
  {
    open: 0,
    awaitingDoorDash: 2,
    awaitingAusPost: 4,
    awaitingClickCollect: 2,
    completed: 9,
    averageScriptTime: 6.8 * 60,
    averageOrderTime: 14.1 * 60,
    new: 15,
  },
  {
    open: 0,
    awaitingDoorDash: 2,
    awaitingAusPost: 4,
    awaitingClickCollect: 0,
    completed: 11,
    averageScriptTime: 6.8 * 60,
    averageOrderTime: 14.1 * 60,
    new: 15,
  },
  {
    open: 0,
    awaitingDoorDash: 2,
    awaitingAusPost: 4,
    awaitingClickCollect: 0,
    completed: 11,
    averageScriptTime: 6.9 * 60,
    averageOrderTime: 14.3 * 60,
    new: 15,
  },
  {
    open: 0,
    awaitingDoorDash: 2,
    awaitingAusPost: 0,
    awaitingClickCollect: 0,
    completed: 15,
    averageScriptTime: 6.9 * 60,
    averageOrderTime: 14.7 * 60,
    new: 15,
  },
  {
    open: 0,
    awaitingDoorDash: 2,
    awaitingAusPost: 0,
    awaitingClickCollect: 0,
    completed: 15,
    averageScriptTime: 6.9 * 60,
    averageOrderTime: 14.7 * 60,
    new: 15,
  },
  {
    open: 0,
    awaitingDoorDash: 2,
    awaitingAusPost: 0,
    awaitingClickCollect: 0,
    completed: 15,
    averageScriptTime: 7.1 * 60,
    averageOrderTime: 14.7 * 60,
    new: 15,
  },
  {
    open: 0,
    awaitingDoorDash: 2,
    awaitingAusPost: 0,
    awaitingClickCollect: 0,
    completed: 15,
    averageScriptTime: 7.1 * 60,
    averageOrderTime: 15.1 * 60,
    new: 15,
  },
  {
    open: 0,
    awaitingDoorDash: 2,
    awaitingAusPost: 0,
    awaitingClickCollect: 0,
    completed: 15,
    averageScriptTime: 7.3 * 60,
    averageOrderTime: 15.1 * 60,
    new: 15,
  },
  {
    open: 3,
    awaitingDoorDash: 2,
    awaitingAusPost: 0,
    awaitingClickCollect: 1,
    completed: 15,
    averageScriptTime: 7.3 * 60,
    averageOrderTime: 15.1 * 60,
    new: 15,
  },
  {
    open: 2,
    awaitingDoorDash: 2,
    awaitingAusPost: 1,
    awaitingClickCollect: 1,
    completed: 15,
    averageScriptTime: 7.2 * 60,
    averageOrderTime: 15.1 * 60,
    new: 15,
  },
  {
    open: 3,
    awaitingDoorDash: 2,
    awaitingAusPost: 1,
    awaitingClickCollect: 1,
    completed: 15,
    averageScriptTime: 7.2 * 60,
    averageOrderTime: 15.1 * 60,
    new: 15,
  },
  {
    open: 4,
    awaitingDoorDash: 2,
    awaitingAusPost: 1,
    awaitingClickCollect: 1,
    completed: 15,
    averageScriptTime: 7.2 * 60,
    averageOrderTime: 15.1 * 60,
    new: 15,
  },
  {
    open: 4,
    awaitingDoorDash: 2,
    awaitingAusPost: 1,
    awaitingClickCollect: 1,
    completed: 15,
    averageScriptTime: 7.2 * 60,
    averageOrderTime: 15.1 * 60,
    new: 15,
  },
  {
    open: 6,
    awaitingDoorDash: 2,
    awaitingAusPost: 1,
    awaitingClickCollect: 1,
    completed: 15,
    averageScriptTime: 7.2 * 60,
    averageOrderTime: 15.1 * 60,
    new: 15,
  },
  {
    open: 5,
    awaitingDoorDash: 3,
    awaitingAusPost: 0,
    awaitingClickCollect: 1,
    completed: 16,
    averageScriptTime: 7.2 * 60,
    averageOrderTime: 15.1 * 60,
    new: 15,
  },
  {
    open: 3,
    awaitingDoorDash: 3,
    awaitingAusPost: 0,
    awaitingClickCollect: 3,
    completed: 16,
    averageScriptTime: 7.2 * 60,
    averageOrderTime: 15.1 * 60,
    new: 15,
  },
]

const showDevControls = true;

type DashboardProps = {

}

export const Dashboard: FunctionComponent<DashboardProps> = ({ }) => {
  // const [dashboardValues, setDashboardValues] = useState(initialDummyData);
  const [dashLoopState, setDashLoopState] = useState(0);

  const dashboardValues = (): any => {
    return dashboardValuesLoop[dashLoopState % dashboardValuesLoop.length];
  }

  const dashboardTiles = (type: TileType): Array<DashboardTile> => {
    switch (type) {
      case TileType.Primary:
        return [
          {
            id: 'open',
            label: 'Open orders',
            value: dashboardValues().open,
            valueType: TileValueType.Number,
            icon: iconOpen,
            state: dashboardValues().open === 0 ? TileState.Positive : TileState.Overdue
          },
          {
            id: 'awaitingDoorDash',
            label: 'Awaiting\nDoorDash pickup',
            value: dashboardValues().awaitingDoorDash,
            valueType: TileValueType.Number,
            icon: iconDoordash,
          },
          {
            id: 'awaitingAusPost',
            label: 'Awaiting\nAusPost pickup',
            value: dashboardValues().awaitingAusPost,
            valueType: TileValueType.Number,
            icon: iconAuspost,
          },
          {
            id: 'awaitingClickCollect',
            label: 'Awaiting\nClick & Collect',
            value: dashboardValues().awaitingClickCollect,
            valueType: TileValueType.Number,
            icon: iconShopping,
          },
        ]
      case TileType.Secondary:
        return [
          {
            id: 'completed',
            label: 'Completed today',
            value: dashboardValues().completed,
            valueType: TileValueType.Number,
            state: TileState.Positive
          },
          {
            id: 'averageScriptTime',
            label: 'Average script\ndispensing time',
            value: dashboardValues().averageScriptTime,
            valueType: TileValueType.Time,
          },
          {
            id: 'averageOrderTime',
            label: 'Average order\nfulfilment time',
            value: dashboardValues().averageOrderTime,
            valueType: TileValueType.Time,
          },
          // {
          //   id: 'new',
          //   label: 'New orders today',
          //   value: dashboardValues.new,
          //   valueType: TileValueType.Number,
          // },
        ]
    }
  }

  const formatTime = (seconds: number): string => {
    const min = Math.floor(seconds / 60);
    const sec = Math.floor(seconds % 60);
    return `${min}:${sec.toString().padStart(2, '0')}`;
  }

  const interval = 2000;
  useEffect(() => {
    const timer = window.setInterval(() => {
      setDashLoopState(prevState => prevState + 1);
    }, interval);
    return () => { window.clearInterval(timer) };
  }, []);

  return (
    <StyledDashboard className="Dashboard">
      <div className="Dashboard_gridPrimary">
        {dashboardTiles(TileType.Primary).map((tile: DashboardTile, i: number) => {
          return (
            <div className={`Dashboard_tile primary ${tile.state}`} key={`tile-${tile.id}`}>
              <div>
                {tile.icon &&
                  <img className={`Tile_icon ${['open', 'awaitingClickCollect'].includes(tile.id) && 'tintable'}`} src={tile.icon} alt="" />
                }
                <p className="Tile_label bold">{tile.label}</p>
              </div>
              
              {tile.valueType === TileValueType.Number &&
                <p className="Tile_value bold">{tile.value}</p>
              }
            </div>
          )
        })}
      </div>
      <div className="Dashboard_gridSecondary">
        {dashboardTiles(TileType.Secondary).map((tile: DashboardTile, i: number) => {
          return (
            <div className={`Dashboard_tile secondary ${tile.state}`} key={`tile-${tile.id}`}>
              {tile.icon &&
                <img src={tile.icon} alt="" />
              }
              
              <p className="Tile_label bold">{tile.label}</p>
              
              {tile.valueType === TileValueType.Number &&
                <p className="Tile_value bold">{tile.value}</p>
              }
              {tile.valueType === TileValueType.Time &&
                <p className="Tile_value bold">
                  {formatTime(tile.value)}
                  {/* {tile.value / 60} */}
                  {/* <span className="Tile_valueSuffix">min</span> */}
                </p>
              }
            </div>
          )
        })}
      </div>

      {showDevControls &&
        <div className="Dashboard_dev">
          
        </div>
      }
    </StyledDashboard>
  );
}

const StyledDashboard = styled.div`
  padding: 0 88px;
  margin: auto 0;
  overflow: hidden;

  .Dashboard_gridPrimary {
    width: 100%;
    display: grid;
    grid-template-columns: 520px 1fr 1fr 1fr;
    grid-template-rows: 418px;
    grid-gap: 24px;
    gap: 24px;
    margin-bottom: 38px;

    .Dashboard_tile {
      background: white;
      border-radius: 18px;
      
      &.primary {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 38px 38px 29px 54px;

        .Tile_label {
          font-size: 2rem; // 32px
          line-height: 1.3;
          margin: 0;
          white-space: pre-line;
          /* height: 85px; */
        }
        
        .Tile_value {
          font-size: 7.875rem; // 126px
          margin: 0;
        }
      }

      .Tile_icon {
        margin-bottom: 24px;
      }
    }

  }

  .Dashboard_gridSecondary {
    width: 100%;
    display: grid;
    grid-template-columns: 520px 1fr 1fr;
    grid-template-rows: 202px;
    grid-gap: 24px;
    gap: 24px;

    .Dashboard_tile {
      background: white;
      border-radius: 18px;
      
      &.secondary {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 42px 0 52px;

        .Tile_label {
          font-size: 1.75rem; // 28px
          margin: 0;
          white-space: pre-line;
        }

        .Tile_value {
          /* font-size: 5.6875rem; // 91px */
          font-size: 5rem; // 80px
          margin: 0;

          .Tile_valueSuffix {
            /* position: absolute; */
            font-size: 1.5rem; // 24px
            margin: 0 0 0 8px;
          }
        }
        
      }
    }
  }

  .Dashboard_tile {
    border: 6px solid transparent;
    transition:
      color 0.6s cubic-bezier(0.65, 0, 0.35, 1),
      background 0.6s cubic-bezier(0.65, 0, 0.35, 1),
      border-color 0.6s cubic-bezier(0.65, 0, 0.35, 1);

      .Tile_icon.tintable {
        transition: filter 0s ease 0.4s;
      }

    &.positive {
      color: #35C680;

      .Tile_icon.tintable {
        filter: invert(66%) sepia(12%) saturate(2363%) hue-rotate(98deg) brightness(99%) contrast(82%);
      }
    }
    
    &.overdue {
      color: #C72025;
      border-color: #C72025;
      background: #FDEBEF;

      .Tile_icon.tintable {
        filter: invert(13%) sepia(87%) saturate(3525%) hue-rotate(347deg) brightness(111%) contrast(92%);
      }
    }
  }

  /* mobile CSS */
  @media screen and (max-width: 1080px) {
    padding: 20px 20px;
    margin: auto 0;
    overflow: scroll;
    height: fit-content;
    
    .Dashboard_gridPrimary,
    .Dashboard_gridSecondary {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .Dashboard_gridPrimary {
      margin-bottom: 20px;
    }

    .Dashboard_tile {
      flex-direction: row !important;
      align-items: center !important;
      border-width: 4px;
      padding: 20px 30px !important;

      &.primary {
        height: 147px;
      }

      .Tile_icon {
        width: 32px;
        height: auto;
        margin-bottom: 10px !important;
        margin-left: -2px;
      }

      .Tile_label {
        font-size: 3.5rem !important;
      }
      
      .Tile_value {
        font-size: 7rem !important;
      }
    }
  }

  .Dashboard_dev {
    position: fixed;
    bottom: 10px;
    left: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;
