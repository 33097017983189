import { FunctionComponent, useState, useEffect } from "react";
import styled from 'styled-components';

type HeaderProps = {

}

const pharmacyName = 'Smith\'s Pharmacy';

export const Header: FunctionComponent<HeaderProps> = ({ }) => {
  const [currentTime, setCurrentTime] = useState('');
  const [currentDate, setCurrentDate] = useState({ day: '', date: '', dateSuffix: '', month: '' });

  const handleSetTime = (now: Date): void => {
    const hours = now.getHours().toString().padStart(2, '0')
    const minutes = now.getMinutes().toString().padStart(2, '0');
    setCurrentTime(`${hours}:${minutes}`);
  }
  
  const handleSetDate = (now: Date): void => {
    const day = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][now.getDay()];
    const date = now.getDate().toString();
    let dateSuffix;
    switch (date as any) {
      case '1':
      case '21':
      case '31':
        dateSuffix = 'st'
        break;
      case '2':
      case '22':
        dateSuffix = 'nd'
        break;
      case '3':
      case '23':
        dateSuffix = 'rd'
        break;
      default:
        dateSuffix = 'th'
        break;
    }
    const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][now.getMonth()];
    setCurrentDate({
      day,
      date,
      dateSuffix,
      month
    });
  }

  useEffect(() => {
    const now = new Date();
    handleSetTime(now);
    handleSetDate(now);
    const interval = setInterval(() => {
      const now = new Date();
      handleSetTime(now);
      handleSetDate(now);
    }, 500);
    return () => clearInterval(interval);
  }, [setCurrentTime]);

  return (
    <StyledHeader className="Header">
      <div>
        <h1>{pharmacyName}</h1>
        <h2>
          <span>{currentDate.day} </span>
          <span>{currentDate.date}</span>
          <span className="suffix">{currentDate.dateSuffix} </span>
          <span>{currentDate.month}</span>
        </h2>
      </div>
      <p className="Header_time">
        <span className="digit bold">{currentTime[0]}</span>
        <span className="digit bold">{currentTime[1]}</span>
        <span className="colon bold">{currentTime[2]}</span>
        <span className="digit bold">{currentTime[3]}</span>
        <span className="digit bold">{currentTime[4]}</span>
      </p>
    </StyledHeader>
  );
}

const StyledHeader = styled.header`
  width: 100%;
  padding: 76px 88px 88px 88px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  h1, h2 {
    margin: 0;

    .suffix {
      position: relative;
      top: -0.6em;
      font-size: 56%;
    }
  }

  .Header_time {
    display: flex;
    gap: 0.875rem; // 14px
    align-items: center;
    font-size: 3.625rem; // 58px
    margin: 0;
    
    .digit {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 4.6875rem; // 75px
      height: 6.375rem; // 102px
      color: white;
      background: #FFA24B;
      border-radius: 0.375rem; // 6px
    }

    .colon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFA24B;
      width: 1.125rem; // 18px
    }
  }

  /* mobile CSS */
  @media screen and (max-width: 1080px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    z-index: 1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

    h1 {
      font-size: 3rem;
    }

    .Header_time {
      order: -1;
      align-self: flex-end;
    }
  }
`;
