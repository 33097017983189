import { Header } from "./components/Header";
import { Dashboard } from "./components/Dashboard";
import { Footer } from "./components/Footer";

import styled from 'styled-components';

function App() {
  return (
    <StyledApp className="App">
      <Header />
      <Dashboard />
      <Footer />
    </StyledApp>
  );
}

export default App;

const StyledApp = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #F2F2F2;
`;

// set mobile font size at root
const documentElement = document.documentElement;
if (documentElement.clientWidth < 1080) {
  documentElement.style.fontSize = '6px';
} else {
  documentElement.style.fontSize = '16px';
}